import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

import { Client, setDefaultClient } from "micro-graphql-react";

const client = new Client({
  endpoint: localStorage.getItem("apiUrl") || "https://api.usesession.com/query",
});

setDefaultClient(client);

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
