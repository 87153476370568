import React from "react";
import { LinkPageLink } from "./types";

export default ({ link }: { link: LinkPageLink }) => {
  const extraClasses = [];

  if (link.wiggle) {
    extraClasses.push("wiggle");
  }

  let borderColor = link.highlight && link.highlight_color ? link.highlight_color : "white";
  let color = link.highlight && link.highlight_color ? link.highlight_color : "inherit";

  return (
    <a href={link.url}
      className={ "text-black no-underline border border-white flex flex-col justify-center font-semibold bg-white mb-4 p-4 shadow rounded " + extraClasses.join(" ") }
    style={ { minHeight: 50, borderColor, color } }>

      <div>
        {link.name}
      </div>

      {link.available !== null ? (
        <div className="text-xs text-black mt-1">
          {link.available === 0 ? (
            <span>No spots left</span>
          ) : (
            <span>{link.available} spot{link.available === 1 ? '' : 's'} left</span>
          )}
        </div>
      ) : null}

  </a>
  );
};
